import { filterCustomerSystemsFetched, filterTradingSystemsFetched, filterUserSystemsFetched } from './filters.actions';
import { QueryType } from 'models/Queries';
import { FilterOptionsResponse } from 'models/Responses/FilterOptionsResponse';
import { Thunk } from 'store/actions';
import { queryCustomerSystemErased, queryTradingSystemErased, queryUserSystemErased } from 'store/queries/queries.actions';
import { shouldEraseSelectedOption } from 'store/queries/query.helpers';
import { getSelectedCustomerSystemId, getSelectedTradingSystem, getSelectedUserSystemId } from 'store/selectors';
import { isDefinedAndNotEmpty } from 'utils/string/string';

export const tradingSystemsFilterOptionsFetchedThunk =
  (exchange: string, customer: string, response: FilterOptionsResponse, queryType: QueryType): Thunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    if (state.users.current?.features.UnrestrictedListQueryFilters) {
      dispatch(filterTradingSystemsFetched(exchange, customer, response));
      if (isDefinedAndNotEmpty(exchange)) {
        const selectedTradingSystem = getSelectedTradingSystem(queryType)(state);
        if (shouldEraseSelectedOption(customer, true, selectedTradingSystem, response.options)) {
          dispatch(queryTradingSystemErased(queryType));
        }
      }
    } else {
      dispatch(queryTradingSystemErased(queryType));
    }
  };

export const customerSystemsFilterOptionsFetchedThunk =
  (exchange: string, customer: string, tradingSystem: string, response: FilterOptionsResponse, queryType: QueryType): Thunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    if (state.users.current?.features.UnrestrictedListQueryFilters) {
      dispatch(filterCustomerSystemsFetched(exchange, customer, tradingSystem, response));
      if (isDefinedAndNotEmpty(exchange)) {
        const selectedCustomerSystemId = getSelectedCustomerSystemId(queryType)(state);
        if (shouldEraseSelectedOption(customer, true, selectedCustomerSystemId, response.options)) {
          dispatch(queryCustomerSystemErased(queryType));
        }
      }
    } else {
      dispatch(queryCustomerSystemErased(queryType));
    }
  };


export const userSystemsFilterOptionsFetchedThunk =
  (exchange: string, customer: string, tradingSystem: string, customerSystemId: string, response: FilterOptionsResponse, queryType: QueryType): Thunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    if (state.users.current?.features.UnrestrictedListQueryFilters) {
      dispatch(filterUserSystemsFetched(exchange, customer, tradingSystem, customerSystemId, response));
      if (isDefinedAndNotEmpty(exchange)) {
        const selectedUserSystemId = getSelectedUserSystemId(queryType)(state);
        if (shouldEraseSelectedOption(customer, true, selectedUserSystemId, response.options)) {
          dispatch(queryUserSystemErased(queryType));
        }
      }
    } else {
      dispatch(queryUserSystemErased(queryType));
    }
  };
